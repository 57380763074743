<script lang="ts" setup>
import { computed } from 'vue'
import type { Props } from './types'
import OnboardingHero from '@/components/OnboardingContent/Hero/index.vue'
import type { PicturesV2Item } from '@/server/api/buyer-front/types'

const props = withDefaults(defineProps<Props>(), {
  hideTitle: false
})

const coverUrls = computed(() => {
  const coverPicture = props.listing.pictures_v2.find((picture: PicturesV2Item) => picture.type === 'cover')

  const otherPictures = props.listing.pictures_v2.filter((picture: PicturesV2Item) => picture.type === 'gallery')
  const selectedPictures = [coverPicture, ...otherPictures].slice(0, 5) as PicturesV2Item[]
  return selectedPictures.map((picture: PicturesV2Item) => `${picture?.url}?auto=format,compress,enhance`)
})

const minPrice = computed(() => {
  if (props.listing.typologies) {
    const [cheapestTypologyPrice] = props.listing.typologies.map(typology => typology.price).sort((a, b) => a - b)

    if (!cheapestTypologyPrice) return props.listing.price

    return cheapestTypologyPrice
  }

  return props.listing.price
})
</script>

<script lang="ts">
export default {
  name: 'SearchResultsContentHero'
}
</script>

<template>
  <OnboardingHero
    :code="listing.code"
    :name="listing.name"
    :city="listing.city_label"
    :neighborhood="listing.neighborhood_label"
    :min-price
    :typologies="listing.typologies"
    :delivery-date="listing.delivered_at"
    :cover-urls
    :path="listing.path"
    :is-content-locked="hideTitle" />
</template>
